import { Icon } from '@chakra-ui/react';

const ImageIcon = () => (
  <Icon width="21" viewBox="0 0 21 21" color="primary">
    <path
      d="M20.625 18.375V2.625C20.625 1.3875 19.6125 0.375 18.375 0.375H2.625C1.3875 0.375 0.375 1.3875 0.375 2.625V18.375C0.375 19.6125 1.3875 20.625 2.625 20.625H18.375C19.6125 20.625 20.625 19.6125 20.625 18.375ZM6.5625 12.1875L9.375 15.5738L13.3125 10.5L18.375 17.25H2.625L6.5625 12.1875Z"
      fill="#112A09"
    />
  </Icon>
);

export default ImageIcon